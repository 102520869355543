import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/shop.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <div className="order-one">
            <div className="offset-1">
              <div className="adv-div">
                <h1>Shall We Go On An Adventure?</h1>
              </div>
              <div className="new-div">
                <p> Three new editions from Hackney's finest Dave Buonaguidi</p>
              </div>
            </div>
          </div>
          <div className="order-two">
            <img alt="" src="/nelly/101.jpg" />
          </div>
        </div>
        <div className="mainBox">
          <div className="container-fluid">
            <div className="top-container">
              <div className="product-grid">
                {productData.map((brand) => (
                  <div className="product-category" key={brand.id}>
                    <div className="image-container">
                      <Link
                        to={`/shopDetails/${brand.id}`}
                        onClick={handleNavigate}
                      >
                        <img alt="" src={brand.images[0]} />
                      </Link>
                      <h2>{brand.name}</h2>
                      <h4 className="size-h4">{brand.size}</h4>
                      <div className="pp-pre">
                        <h4 className="red-price">
                          <strong>{brand.price}</strong>
                        </h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="jsVideo">
              <div className="one-third-left">
                <div className="is-content">
                  <p className="heading-text">
                    Check out our selection of limited editions for under £200.
                    The perfect gift for the Art Lover in your life!
                  </p>
                </div>
              </div>
              <div className="one-third-right"></div>
            </div>
            <div className="jsVideo">
              <div className="one-third-left">
                <div className="is-content">
                  <p className="heading-text">
                    Treat the art lover in your life to a Nelly Duff Gift
                    Voucher.
                  </p>
                </div>
              </div>
              <div className="one-third-right1"></div>
            </div>
            <div className="jsVideo">
              <div className="one-third-right2"></div>
              <div className="one-third-left">
                <div className="is-content">
                  <p className="heading-text">
                    Get your artworks by 1pm tomorrow with Next Day delivery!
                  </p>
                </div>
              </div>
            </div>

            <div className="jsVideo1">
              <div className="one-third-left1">
                <div className="is-content1">
                  <p className="heading-text">Subscribe to Nelly News</p>
                </div>
                <div className="bkg-yellow">
                  <div className="subscribe">
                    <p>Subscribe</p>
                    <div className="mc-field-group">
                      <input
                        type="email"
                        value=""
                        placeholder="Email address"
                        name="EMAIL"
                      />
                      <input
                        type="text"
                        value=""
                        placeholder="First name"
                        name="FNAME"
                      />
                      <input
                        type="text"
                        value=""
                        placeholder="Surname"
                        name="LNAME"
                      />
                    </div>
                    <div className="content__gdpr">
                      <span>
                        Sign me up! By checking this box you agree to receive
                        email communications from Nelly Duff about upcoming
                        shows, new releases, Nelly News and more. You can opt
                        out at anytime.
                      </span>
                      <input
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        class="button"
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="one-third-right3"></div>
            </div>
            <div className="jsVideo">
              <div className="one-third-left">
                <div className="is-content">
                  <p className="heading-text">
                    Buy your custom frames with us online or in store with
                    delivery or collection - easy!
                  </p>
                </div>
              </div>
              <div className="one-third-right4"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
