import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function Contact() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!surname) newErrors.surname = "surname is required";

    // setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all required fields");
    } else {
      alert("Send success!");
    }
  };
  return (
    <div className="main-container">
      <Header />

      <div className="PrivacyDiv">
        <div className="contact-inner">
          <h1>Contact</h1>
          <div className="align-items-start">
            <div className="contact-block__left">
              <h3>Address</h3>
              <p style={{ marginLeft: "66%" }}>
                156 Columbia R<span class="hide">oa</span>d<br /> London
                <br /> E2 7RG
              </p>
              <h3>Opening Hours</h3>
              <ul>
                <li>
                  <span class="day">Mon - Tues </span>
                  <span class="hours"> Appointment Only</span>
                </li>
                <li>
                  <span class="day">Weds - Friday</span>
                  <span class="hours"> 9am - 6pm</span>
                </li>
                <li>
                  <span class="day">Saturday</span>
                  <span class="hours"> 11am - 6pm</span>
                </li>
                <li>
                  <span class="day">Sunday</span>
                  <span class="hours"> 10am - 5pm</span>
                </li>
                <li>
                  <span class="day">Bank Holidays</span>
                  <span class="hours"> 11am - 5pm</span>
                </li>
              </ul>
            </div>
            <div className="contact-block__mid">
              <img alt="" src="/main/columbia-road.svg"></img>
            </div>
            <div className="contact-block__right">
              <h3>Phone</h3>
              <a class="button alt calltrack" href="tel:020 7033 9683 ">
                <span class="cta">Call us: </span>020 7033 9683
              </a>
              <h3>Enquiries</h3>
              <p>support@nellyduff.shop</p>
              <h3>Press</h3>
              <p>support@nellyduff.shop</p>
              <h3>Submissions</h3>
              <p>support@nellyduff.shop</p>
            </div>
          </div>
          <div className="line">
            <h2> How to find us </h2>
            <img alt="" src="/main/image.png" />
            <div className="line-p">
              <p>
                Please note, Nelly Duff is partially accessible. The gallery is
                spread over two floors, the ground floor is accessible via
                street entrance and is wheelchair friendly. Our first floor is
                only accessible via staircase, however if during your visit you
                would like to see anything that isn't on display on the ground
                floor, our lovely staff will be happy to bring down anything you
                want to look at.
              </p>
            </div>
          </div>
          <div className="line">
            <h2>Send us a message</h2>
            <div className="line-p">
              <div className="line-p-inner">
                <div className="line-row">
                  <div className="line-name">
                    <label>First Name*</label>
                    <br />
                    <input
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      value={firstName}
                      type="text"
                      name="your-firstname"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="line-name">
                    <label>Surname*</label>
                    <br />
                    <input
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      value={surname}
                      type="text"
                      name="surname"
                      onChange={(e) => setSurname(e.target.value)}
                    />
                  </div>
                </div>
                <div className="line-row">
                  <div className="line-name">
                    <label>Email*</label>
                    <br />
                    <input
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      value={email}
                      type="email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="line-name">
                    <label>Phone</label>
                    <br />
                    <input
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      value=""
                      type="text"
                      name="your-Phone"
                    />
                  </div>
                </div>
                <div className="line-row">
                  <div className="line-name">
                    <label>Your Message</label>
                    <br />
                    <textarea
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      value=""
                      type="text"
                      name="your-firstname"
                    />
                  </div>
                </div>
                <input
                  className="has-spinner"
                  type="submit"
                  value="Send"
                  onClick={handlePlaceOrder}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
