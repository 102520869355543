import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="bot-top">
            <div className="foot-img1">
              <img alt="" src="/main/106.jpg" />
            </div>
            <div className="own-art">
              <img alt="" src="/main/cta-logo.webp" />
              <p>Don't miss out on your dream artwork.</p>
            </div>
          </div>
          <div className="bot-bot">
            <ul>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy</Link>
              </li>
              <li>
                <Link to="/terms">Terms</Link>
              </li>
            </ul>
            <hr />
            <div className="p-p">
              <p>©</p>&nbsp;&nbsp;
              <img alt="" src="/main/footer-logo.svg" />
              &nbsp;&nbsp;
              <p>2024</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
