import "../css/header.css";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";
import { Link } from "react-router-dom";

function Header() {
  const { cartItems } = useContext(CartContext);
  const navigate = useNavigate();
  const toCart = () => {
    navigate("/cartpage");
  };

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="headerMid">
            <nav className="navigation">
              <ul className=" main-nav">
                <li className="menu-item">
                  <Link to="/" className="homeToA">
                    HOME
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/contact" className="homeToA">
                    Contact
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/privacy">Privacy</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="leftImg">
            <img alt="" src="/head-foot/head.gif" />
          </div>
          <div className="rightT" onClick={toCart}>
            <p className="cartItemCount">{cartItems.length}</p>
            <img alt="" src="/head-foot/basket.svg" style={{ width: "31px" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
