import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities, setQuantities] = useState(1);
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleAddToCart = () => {
    addToCart(product.id, quantities);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/cartpage");
  };
  if (!product) {
    return <div>Product not found</div>;
  }

  const calculateSave = (price, prePrice) => {
    const priceNumber = parseFloat(price.replace("£", ""));
    const prePriceNumber = parseFloat(prePrice.replace("£", ""));
    return ((prePriceNumber - priceNumber) / prePriceNumber) * 100;
  };

  const calculateGap = (price, prePrice) => {
    const priceNumber = parseFloat(price.replace("£", ""));
    const prePriceNumber = parseFloat(prePrice.replace("£", ""));
    return prePriceNumber - priceNumber;
  };

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel-container">
              <div className="carousel-buttons">
                {product.images.map((image, index) => (
                  <button key={index} onClick={() => setCurrentIndex(index)}>
                    <img src={image} alt={`${product.name}`} />
                  </button>
                ))}
              </div>
              <div className="carousel">
                {product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`${product.name}`}
                    style={{
                      display: index === currentIndex ? "block" : "none",
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="goBackToShop">
              <a href="/">{"<"}&nbsp;&nbsp;&nbsp;Go Back To Shopping.</a>
            </div>
          </div>

          <div className="product-info">
            <div className="g-0">
              <div className="bg-quaternary">
                <h3>{product.name}</h3>
              </div>
            </div>
            <div className="div-two-price">
              <h4 className="stock-in">{product.price}</h4>
              <p>{product.preprice}</p>
            </div>

            <div className="summary-container">
              <div className="template_content">
                <div className="btnDiv">
                  <button className="add-to-cart" onClick={handleAddToCart}>
                    Add to Basket
                  </button>
                </div>
              </div>
              <div className="own-art-box__heading">
                <div class="own-art-box__title"> Buy with own art</div>
                <img
                  width="40"
                  height="40"
                  class=" lazyloaded"
                  src="/main/cta-logo.webp"
                />
                <img
                  alt="PBF Widget"
                  itemprop="logo"
                  width="80"
                  src="https://cdn.shopify.com/s/files/1/0558/8120/3772/files/novuna.png?v=1644329978"
                />
              </div>
            </div>
            <div class="own-art-box__copy">
              Pay 10 interest free instalments from&nbsp;
              <strong>{product.instal}</strong>
            </div>
            <div className="tabs-stage">
              <div id="tab-info" className="tab" style={{ display: "block" }}>
                <h2>Info</h2>
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr>
                      <th>Dimensions:</th>
                      <td>30.3 × 30.5 cm</td>
                    </tr>
                    <tr>
                      <th>Medium:</th>
                      <td> Hot Foil and Screenprint</td>
                    </tr>
                    <tr>
                      <th>SKU:</th>
                      <td>JPL47</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
