const productData = [
  {
    id: 1,
    name: "Banksy vs Bristol Museum show leaflet 2009",
    price: "£300.00",
    size: "10 × 21 cm",
    medium: "Leaflet",
    sku: "BM21",
    instal: "£30.00",
    images: ["/nelly/1001.jpg", "/nelly/1001a.jpg", "/nelly/1001b.jpg"],
  },
  {
    id: 2,
    name: "Tropicalislands",
    price: "£495.00",
    size: "	80 × 100 cm",
    medium: "Archival giclee print",
    sku: "MOR02",
    instal: "£49.50",
    images: ["/nelly/1002.jpg", "/nelly/1002a.jpg"],
  },
  {
    id: 3,
    name: "Still Love",
    price: "£895.00",
    instal: "£89.50",
    size: "70 × 100 cm",
    medium: "	Screenprint with hand finished acrylic paint, pencil and ink",
    sku: "SOZ374",
    images: ["/nelly/1003.jpg", "/nelly/1003a.jpg", "/nelly/1003b.jpg"],
  },
  {
    id: 4,
    name: "Just Come Home – Fan Black",
    price: "£145.00",
    size: "	14 × 8 cm",
    medium: "Mixed Media Painting",
    sku: "SOZ406",
    instal: "£14.50",
    images: ["/nelly/1004.jpg"],
  },
  {
    id: 5,
    name: "Just Come Home – Miss V Hunt",
    price: "£145.00",
    size: "14 × 8 cm",
    medium: "Mixed Media Painting",
    sku: "SOZ405",
    instal: "£14.50",
    images: ["/nelly/1005.jpg"],
  },
  {
    id: 6,
    name: "Just Come Home – Miss Ross",
    price: "£145.00",
    size: "	14 × 8 cm",
    medium: "	Mixed Media Painting",
    sku: "SOZ404",
    instal: "£14.50",
    images: ["/nelly/1006.jpg"],
  },
  {
    id: 7,
    name: "Garden Chair – Original",
    price: "£345.00",
    size: "29 × 42 cm",
    medium: "Mixed Media Painting",
    sku: "SOZ402",
    instal: "£34.50",
    images: ["/nelly/1007.jpg"],
  },
  {
    id: 8,
    name: "Parlour Chair – Original",
    price: "£345.00",
    size: "	29 × 42 cm",
    medium: "Mixed Media Painting",
    sku: "SOZ401",
    instal: "£34.50",
    images: ["/nelly/1008.jpg"],
  },
  {
    id: 9,
    name: "Beigel Bake Bronze – Black",
    price: "£125.00",
    instal: "£12.50",
    size: "30.3 × 30.5 cm",
    medium: "Hot Foil and Screenprint",
    sku: "JPL47",
    images: ["/nelly/1009.jpg", "/nelly/1009a.jpg", "/nelly/1009b.jpg"],
  },
  {
    id: 10,
    name: "Rasheed Does a Kickflip – Gold",
    price: "£195.00",
    size: "50 × 70 cm",
    medium: "	Hot Foiling",
    sku: "MRB29",
    instal: "£19.50",
    images: ["/nelly/10010.jpg", "/nelly/10010a.jpg", "/nelly/10010b.jpg"],
  },
  {
    id: 11,
    name: "Big Rain",
    price: "£195.00",
    size: "53 × 73 cm",
    medium: "Lino print",
    sku: "CB254",
    instal: "£19.50",
    images: [
      "/nelly/10011.jpg",
      "/nelly/10011a.jpg",
      "/nelly/10011b.jpg",
      "/nelly/10011c.jpg",
      "/nelly/10011d.jpg",
    ],
  },
  {
    id: 12,
    name: "Michelle Series",
    price: "£725.00",
    size: "70 × 82 cm",
    medium: "	Archival Giclée with Silkscreen Varnishes",
    sku: "",
    instal: "",
    images: ["/nelly/10012.jpg", "/nelly/10012a.jpg", "/nelly/10012b.jpg"],
  },
  {
    id: 13,
    name: "Rasheed Does a Kickflip – Gold",
    price: "£150.00",
    size: "50 × 70 cm",
    medium: "	Hot Foiling",
    sku: "AB75",
    instal: "£15.00",
    images: ["/nelly/10013.jpg", "/nelly/10013a.jpg", "/nelly/10013b.png"],
  },
  {
    id: 14,
    name: "Hello I Love You – 24ct Gold",
    price: "£250.00",
    size: "	10 × 20 cm",
    medium: "Lino Print with 24ct Gold Leaf",
    sku: "CB279",
    instal: "£25.00",
    images: ["/nelly/10010.jpg", "/nelly/10010a.jpg", "/nelly/10010b.jpg"],
  },
];

export default productData;
