import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price.replace("£", ""));
        total += price * item.quantity;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price.replace("£", ""));
    return price * quantity; // 计算总价
  };

  const handleInputChange = (id, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(id, newQuantity);
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="cart-page">
        <div className="basket-title">
          <h1>Shopping Basket</h1>
        </div>
        <div className="cart-total">
          <div className="cart-items">
            {cartItems.length === 0 ? (
              <h3 style={{ color: "black", textAlign: "center" }}>
                Your Cart is Empty!
              </h3>
            ) : (
              <div className="product-grid1">
                {cartItems.map((item) => {
                  const product = getProductById(item.id);
                  return (
                    <div className="product-category11" key={product.id}>
                      <div className="xmark-div">
                        <div className="svg-xmark">
                          <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => removeFromCart(item.id)}
                          />
                        </div>
                      </div>
                      <div className="image-container">
                        <img alt="" src={product.images[0]} />
                      </div>
                      <div className="product-info11">
                        <h2>{product.name}</h2>
                        <h4 className="size-h4">{product.price}</h4>
                        <div className="pp-pre">
                          <input
                            type="number"
                            value={item.quantity}
                            min="1"
                            onChange={(e) => handleInputChange(item.id, e)}
                            className="quantity-input11"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {cartItems.length > 0 && (
            <div className="cart-summary">
              <div className="rightSum">
                <h2 className="basketDiv">Basket Totals</h2>

                <table className="summary-details">
                  <tbody className="sumTbody">
                    <tr className="sumTr">
                      <th className="subtotalTh">Subtotal</th>
                      <th className="subtotalTxt">
                        £{calculateTotal().toFixed(2)}
                      </th>
                    </tr>
                    <tr className="sumTr">
                      <th className="subtotalTh">Total</th>
                      <th className="subtotalTxt1">
                        <strong>£{calculateTotal().toFixed(2)}</strong>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <p className="own-art">
                  Own Art, Shipping and Tax are calculated at checkout
                </p>
                <button className="checkout-button" onClick={toCheckOut}>
                  Proceed to checkout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CartPage;
